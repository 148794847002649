var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ul',{staticClass:"kt-sticky-toolbar",staticStyle:{"margin-top":"30px"}},[_c('li',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:('More demos'),expression:"'More demos'",modifiers:{"hover":true,"left":true}}],staticClass:"kt-sticky-toolbar__item kt-sticky-toolbar__item--success",attrs:{"id":"kt_demo_panel_toggle"}},[_vm._m(0)]),_c('li',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:('Layout builder'),expression:"'Layout builder'",modifiers:{"hover":true,"left":true}}],staticClass:"kt-sticky-toolbar__item kt-sticky-toolbar__item--brand"},[_c('router-link',{attrs:{"href":"#","to":{ name: 'builder' }}},[_c('i',{staticClass:"flaticon2-gear"})])],1),_c('li',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:('Documentation'),expression:"'Documentation'",modifiers:{"hover":true,"left":true}}],staticClass:"kt-sticky-toolbar__item kt-sticky-toolbar__item--warning"},[_vm._m(1)])]),_c('div',{ref:"kt_demo_panel",staticClass:"kt-demo-panel",attrs:{"id":"kt_demo_panel"}},[_vm._m(2),_c('div',{staticClass:"kt-demo-panel__body kt-scroll"},[_c('div',{staticClass:"kt-demo-panel__item",class:{
          'kt-demo-panel__item--active': _vm.isActiveDemo('demo1')
        }},[_c('div',{staticClass:"kt-demo-panel__item-title"},[_vm._v(" Demo1 ")]),_vm._m(3)]),_c('div',{staticClass:"kt-demo-panel__item",class:{
          'kt-demo-panel__item--active': _vm.isActiveDemo('demo2')
        }},[_c('div',{staticClass:"kt-demo-panel__item-title"},[_vm._v(" Demo 2 ")]),_vm._m(4)]),_c('div',{staticClass:"kt-demo-panel__item",class:{
          'kt-demo-panel__item--active': _vm.isActiveDemo('demo3')
        }},[_c('div',{staticClass:"kt-demo-panel__item-title"},[_vm._v(" Demo 3 ")]),_vm._m(5)]),_c('div',{staticClass:"kt-demo-panel__item",class:{
          'kt-demo-panel__item--active': _vm.isActiveDemo('demo4')
        }},[_c('div',{staticClass:"kt-demo-panel__item-title"},[_vm._v(" Demo 4 ")]),_vm._m(6)]),_c('div',{staticClass:"kt-demo-panel__item",class:{
          'kt-demo-panel__item--active': _vm.isActiveDemo('demo5')
        }},[_c('div',{staticClass:"kt-demo-panel__item-title"},[_vm._v(" Demo 5 ")]),_vm._m(7)]),_c('div',{staticClass:"kt-demo-panel__item",class:{
          'kt-demo-panel__item--active': _vm.isActiveDemo('demo6')
        }},[_c('div',{staticClass:"kt-demo-panel__item-title"},[_vm._v(" Demo 6 ")]),_vm._m(8)]),_c('div',{staticClass:"kt-demo-panel__item",class:{
          'kt-demo-panel__item--active': _vm.isActiveDemo('demo7')
        }},[_c('div',{staticClass:"kt-demo-panel__item-title"},[_vm._v(" Demo 7 ")]),_vm._m(9)]),_c('div',{staticClass:"kt-demo-panel__item",class:{
          'kt-demo-panel__item--active': _vm.isActiveDemo('demo8')
        }},[_c('div',{staticClass:"kt-demo-panel__item-title"},[_vm._v(" Demo 8 ")]),_vm._m(10)]),_c('div',{staticClass:"kt-demo-panel__item",class:{
          'kt-demo-panel__item--active': _vm.isActiveDemo('demo9')
        }},[_c('div',{staticClass:"kt-demo-panel__item-title"},[_vm._v(" Demo 9 ")]),_vm._m(11)]),_c('div',{staticClass:"kt-demo-panel__item",class:{
          'kt-demo-panel__item--active': _vm.isActiveDemo('demo10')
        }},[_c('div',{staticClass:"kt-demo-panel__item-title"},[_vm._v(" Demo 10 ")]),_vm._m(12)]),_c('div',{staticClass:"kt-demo-panel__item",class:{
          'kt-demo-panel__item--active': _vm.isActiveDemo('demo11')
        }},[_c('div',{staticClass:"kt-demo-panel__item-title"},[_vm._v(" Demo 11 ")]),_vm._m(13)]),_c('div',{staticClass:"kt-demo-panel__item",class:{
          'kt-demo-panel__item--active': _vm.isActiveDemo('demo12')
        }},[_c('div',{staticClass:"kt-demo-panel__item-title"},[_vm._v(" Demo 12 ")]),_vm._m(14)]),_c('div',{staticClass:"kt-demo-panel__item",class:{
          'kt-demo-panel__item--active': _vm.isActiveDemo('demo13')
        }},[_c('div',{staticClass:"kt-demo-panel__item-title"},[_vm._v(" Demo 13 ")]),_vm._m(15)]),_c('div',{staticClass:"kt-demo-panel__item",class:{
          'kt-demo-panel__item--active': _vm.isActiveDemo('demo14')
        }},[_c('div',{staticClass:"kt-demo-panel__item-title"},[_vm._v(" Demo 14 ")]),_vm._m(16)]),_c('a',{staticClass:"kt-demo-panel__purchase btn btn-brand btn-elevate btn-bold btn-upper",attrs:{"href":"https://themeforest.net/item/metronic-responsive-admin-dashboard-template/4021469?ref=keenthemes","target":"_blank"}},[_vm._v(" Buy Metronic Now! ")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"flaticon2-drop"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://keenthemes.com/metronic/?page=docs","target":"_blank"}},[_c('i',{staticClass:"flaticon2-telegram-logo"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kt-demo-panel__head"},[_c('h3',{staticClass:"kt-demo-panel__title"},[_vm._v(" Select A Demo ")]),_c('a',{staticClass:"kt-demo-panel__close",attrs:{"href":"#","id":"kt_demo_panel_close"}},[_c('i',{staticClass:"flaticon2-delete"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kt-demo-panel__item-preview"},[_c('img',{attrs:{"src":require("@/assets/media/demos/preview/demo1.jpg"),"alt":""}}),_c('div',{staticClass:"kt-demo-panel__item-preview-overlay"},[_c('a',{staticClass:"btn btn-brand btn-elevate",attrs:{"href":"https://keenthemes.com/metronic/preview/vue/demo1"}},[_vm._v(" Preview ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kt-demo-panel__item-preview"},[_c('img',{attrs:{"src":require("@/assets/media/demos/preview/demo2.jpg"),"alt":""}}),_c('div',{staticClass:"kt-demo-panel__item-preview-overlay"},[_c('a',{staticClass:"btn btn-brand btn-elevate disabled",attrs:{"href":"#"}},[_vm._v(" Coming soon ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kt-demo-panel__item-preview"},[_c('img',{attrs:{"src":require("@/assets/media/demos/preview/demo3.jpg"),"alt":""}}),_c('div',{staticClass:"kt-demo-panel__item-preview-overlay"},[_c('a',{staticClass:"btn btn-brand btn-elevate disabled",attrs:{"href":"#"}},[_vm._v(" Coming soon ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kt-demo-panel__item-preview"},[_c('img',{attrs:{"src":require("@/assets/media/demos/preview/demo4.jpg"),"alt":""}}),_c('div',{staticClass:"kt-demo-panel__item-preview-overlay"},[_c('a',{staticClass:"btn btn-brand btn-elevate disabled",attrs:{"href":"#"}},[_vm._v(" Coming soon ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kt-demo-panel__item-preview"},[_c('img',{attrs:{"src":require("@/assets/media/demos/preview/demo5.jpg"),"alt":""}}),_c('div',{staticClass:"kt-demo-panel__item-preview-overlay"},[_c('a',{staticClass:"btn btn-brand btn-elevate disabled",attrs:{"href":"#"}},[_vm._v(" Coming soon ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kt-demo-panel__item-preview"},[_c('img',{attrs:{"src":require("@/assets/media/demos/preview/demo6.jpg"),"alt":""}}),_c('div',{staticClass:"kt-demo-panel__item-preview-overlay"},[_c('a',{staticClass:"btn btn-brand btn-elevate disabled",attrs:{"href":"#"}},[_vm._v(" Coming soon ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kt-demo-panel__item-preview"},[_c('img',{attrs:{"src":require("@/assets/media/demos/preview/demo7.jpg"),"alt":""}}),_c('div',{staticClass:"kt-demo-panel__item-preview-overlay"},[_c('a',{staticClass:"btn btn-brand btn-elevate disabled",attrs:{"href":"#"}},[_vm._v(" Coming soon ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kt-demo-panel__item-preview"},[_c('img',{attrs:{"src":require("@/assets/media/demos/preview/demo8.jpg"),"alt":""}}),_c('div',{staticClass:"kt-demo-panel__item-preview-overlay"},[_c('a',{staticClass:"btn btn-brand btn-elevate disabled",attrs:{"href":"#"}},[_vm._v(" Coming soon ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kt-demo-panel__item-preview"},[_c('img',{attrs:{"src":require("@/assets/media/demos/preview/demo9.jpg"),"alt":""}}),_c('div',{staticClass:"kt-demo-panel__item-preview-overlay"},[_c('a',{staticClass:"btn btn-brand btn-elevate disabled",attrs:{"href":"#"}},[_vm._v(" Coming soon ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kt-demo-panel__item-preview"},[_c('img',{attrs:{"src":require("@/assets/media/demos/preview/demo10.jpg"),"alt":""}}),_c('div',{staticClass:"kt-demo-panel__item-preview-overlay"},[_c('a',{staticClass:"btn btn-brand btn-elevate disabled",attrs:{"href":"#"}},[_vm._v(" Coming soon ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kt-demo-panel__item-preview"},[_c('img',{attrs:{"src":require("@/assets/media/demos/preview/demo11.jpg"),"alt":""}}),_c('div',{staticClass:"kt-demo-panel__item-preview-overlay"},[_c('a',{staticClass:"btn btn-brand btn-elevate disabled",attrs:{"href":"#"}},[_vm._v(" Coming soon ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kt-demo-panel__item-preview"},[_c('img',{attrs:{"src":require("@/assets/media/demos/preview/demo12.jpg"),"alt":""}}),_c('div',{staticClass:"kt-demo-panel__item-preview-overlay"},[_c('a',{staticClass:"btn btn-brand btn-elevate disabled",attrs:{"href":"#"}},[_vm._v(" Coming soon ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kt-demo-panel__item-preview"},[_c('img',{attrs:{"src":require("@/assets/media/demos/preview/demo13.jpg"),"alt":""}}),_c('div',{staticClass:"kt-demo-panel__item-preview-overlay"},[_c('a',{staticClass:"btn btn-brand btn-elevate disabled",attrs:{"href":"#"}},[_vm._v(" Coming soon ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kt-demo-panel__item-preview"},[_c('img',{attrs:{"src":require("@/assets/media/demos/preview/demo14.jpg"),"alt":""}}),_c('div',{staticClass:"kt-demo-panel__item-preview-overlay"},[_c('a',{staticClass:"btn btn-brand btn-elevate disabled",attrs:{"href":"#"}},[_vm._v(" Coming soon ")])])])
}]

export { render, staticRenderFns }